@import '~animate.css/animate.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import '~vue-slick-carousel/dist/vue-slick-carousel.css'
@import '~vue-slick-carousel/dist/vue-slick-carousel-theme.css'
@import 'variables'
@import 'components'
@import 'utilities'

\:root
	--color-lighter-grey: #d1d1d1
	--color-light-grey: #707070
	--color-dark-grey: #2b2b2b
	--color-darker-grey: #1a1718
	--color-red: #c31635
	--color-dark-red: #97162d

	--margin-sm: 0.5rem
	--margin-md: 1rem

	--radius-small: 0.5rem

	@media screen and (max-width: $tablet-max)
		--content-padding: 1.5rem

	@media screen and (min-width: $desktop-min)
		--content-padding: 2.5rem

*
	box-sizing: border-box

input, textarea
	&:focus
		outline: none
		box-shadow: 0 0 0 1px var(--color-red)
		border-color: var(--color-red) !important

html,
body
	margin: 0

html
	font-family: "Helvetica Neue", sans-serif
	font-weight: 400
	color: var(--color-light-grey)

	@media screen and (max-width: $tablet-max)
		font-size: 15px

	@media screen and (min-width: $desktop-min)
		font-size: 18px

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a:not(.cta)
	margin: 0
	white-space: pre-wrap
	letter-spacing: 0.01rem

	&.black
		color: black

	&.white
		color: white

	&.grey
		color: var(--color-light-grey)

	&.red
		color: var(--color-red)

	&.underline
		text-decoration: underline

input, textarea
	letter-spacing: 0.01rem

h1
	font-weight: 500
	line-height: 1
	color: var(--color-red)

h2
	font-weight: 400
	line-height: 0.9

h3
	font-weight: 400

h4
	font-weight: 400

h5
	font-weight: 300

h6
	font-weight: 500

p, span, label
	font-weight: 300
	line-height: 1.25
	letter-spacing: 0.02rem

span
	margin: 0

button
	padding: 0
	background: none
	border: none
	cursor: pointer

a
	text-decoration: none
	color: inherit
	cursor: pointer

::placeholder
	color: var(--color-light-grey)
	opacity: 1

@media screen and (max-width: $tablet-max)
	h1
		font-size: 2.7rem

	h2
		font-size: 2.7rem

	h3
		font-size: 1.2rem

	h4
		font-size: 1.2rem

	h5
		font-size: 1rem

	h6
		font-size: 0.8rem

	p, span
		font-size: 0.8rem

@media screen and (min-width: $desktop-min)
	h1
		font-size: 3.8rem

	h2
		font-size: 3.3rem

	h3
		font-size: 2rem

	h4
		font-size: 1.3rem

	h5
		font-size: 1.1rem

	h6
		font-size: 1rem

	p, span, label
		font-size: 0.8rem

input,
textarea
	font-family: inherit
	font-size: 0.8rem
	border: none

	&.text-field
		padding: 0.8rem
		background: #e0e0e0
	border-radius: var(--radius-small)

.page
	min-height: 100vh
	position: relative
	display: flex
	flex-direction: column

.page-inner
	flex-grow: 1
	padding-top: 5rem
	position: relative
	display: flex
	flex-direction: column

.max-page-width
	margin-left: auto
	margin-right: auto
	width: 100%
	max-width: 1440px
	position: relative

.padded-content
	padding: var(--content-padding)

.fullsize-absolute
	width: 100%
	height: 100%
	position: absolute
	top: 0
	left: 0

.bg-pattern
	background: url('/images/bg-pattern.png') center / cover

	&.grey
		background-color: var(--color-dark-grey)
		color: white

	&.red
		background-color: var(--color-red)

.bg-grey
	background-color: var(--color-dark-grey)
	color: white

.image-slide
	width: 100%
	height: 100%
	object-fit: cover
	pointer-events: none

@media screen and (max-width: $tablet-max)
	.desktop-only
		display: none !important

@media screen and (min-width: $desktop-min)
	.mobile-only
		display: none !important

.profile-form
	display: flex
	flex-direction: column

	input
		font-size: 0.8rem

	input:not([type="file"]):not(.multiselect__input),
	textarea
		background: none
		border: 1px solid var(--color-lighter-grey)

	.form-row
		display: flex

		@media screen and (max-width: $tablet-max)
			flex-direction: column

		@media screen and (min-width: $desktop-min)
			flex-wrap: wrap

			> *
				flex: 0 0 50%

	.form-group
		display: flex
		flex-direction: column

		.text-field
			margin: 0.5rem

			&.readonly
				background: #f4f4f4

		.radio-options, .checkbox-options
			margin: 0.5rem
			display: flex

			&.radio-options
				> *:not(:first-child)
					margin-left: 1rem

			&.checkbox-options
				flex-direction: column

				> *
					margin-top: 0.4rem

			input
				margin: 0 0.4rem 0 0

			label
				display: flex
				align-items: center

		.group-label
			margin: 0.5rem 1rem 0
			font-size: 0.9rem
