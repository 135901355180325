.flex-row
	display: flex
	flex-direction: row

.flex-row-center
	display: flex
	flex-direction: row
	align-items: center

.flex-col
	display: flex
	flex-direction: column

.flex-col-center
	display: flex
	flex-direction: column
	align-items: center

.space-between
	justify-content: space-between

.margin-top-xl
	margin-top: 3rem

.margin-top-lg
	margin-top: var(--content-padding)

.margin-top-md
	margin-top: 1rem

.margin-sm
	margin: var(--margin-sm)

.margin-top-sm
	margin-top: 0.5rem

.centered-content
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center

.relative
	position: relative
