@import 'variables'

.cta
	padding: 0.7rem 3rem
	display: flex
	align-items: center
	justify-content: center
	color: white
	background: var(--color-dark-grey)
	border: 1px solid var(--color-dark-grey)
	font-size: 0.9rem
	line-height: 1
	border-radius: 99rem
	transition: background 0.1s ease, color 0.1s ease

	&:hover
		background: var(--color-darker-grey)

	&.red
		background: var(--color-red)
		border-color: var(--color-red)

		&:not(.outline):hover
			background: var(--color-dark-red)
			border-color: var(--color-dark-red)

		&.outline:hover
			color: var(--color-dark-red)
			border-color: var(--color-dark-red)

	&.light-grey
		background: var(--color-light-grey)
		border-color: var(--color-light-grey)

	&.outline
		background: none

		&.light-grey
			color: var(--color-light-grey)

		&.red
			color: var(--color-red)

	> img, > svg
		height: 0.8rem

	&.small
		font-size: 0.8rem
		padding: 0.5rem 1.8rem

	&.large
		height: 2.6rem
		padding: 0.8rem 3rem

	&.disabled
		opacity: 0.5
		pointer-events: none

.section-title
	padding: 1.5rem 0

.dropdown-items
	display: flex
	flex-direction: column

	> *
		padding: 0.7rem 2.4rem
		font-size: 0.9rem
		border-top: 1px solid #d7d7d7

	> *:hover
		background: #f8f8f8

button.dropdown-toggle
	border-radius: 0.5rem

	> img
		margin-top: 0.1rem
		margin-left: 0.5rem
		width: 0.7rem

.pill-selector
	display: flex
	text-align: center
	border-radius: 0.5rem
	overflow: hidden

	label
		flex: 1 1 auto
		padding: 0.8rem
		font-size: 0.9rem
		font-weight: 300
		background: var(--color-red)
		color: white
		cursor: pointer
		transition: background 0.1s ease, color 0.1s ease

		&:hover
			background: var(--color-dark-red)

		&.selected
			background: white
			color: var(--color-red)

		&:not(:first-child)
			border-left: 1px solid white

		.hidden-input
			width: 0
			height: 0
			overflow: hidden

.accordion
	.accordion-item
		&:not(:first-child)
			border-top: 1px solid var(--color-lighter-grey)

.accordion-tab
	padding: 1.5rem 0
	display: flex
	justify-content: space-between
	cursor: pointer

	&.no-toggle
		cursor: auto

	.title
		margin: 0

	> img
		width: 1.5rem

.accordion-item-content
	height: 0
	transition: height 0.5s ease
	overflow: hidden
	--animate-delay: 0.2s

.tags
	display: flex
	flex-wrap: wrap
	grid-gap: 0.3rem

	.tag
		padding: 0.3rem 0.6rem
		text-align: center
		background: #ffebef
		color: var(--color-red)
		border-radius: var(--radius-small)
		font-size: 0.8rem

		&.highlighted
			background: var(--color-red)
			border-color: var(--color-red)
			color: white

.invisible-field
	width: 0
	height: 0
	overflow: hidden

	input
		width: 0
		height: 0

.custom-checkbox
	appearance: none
	width: 1rem
	height: 1rem
	border-radius: 5px

	&:checked
		border: none !important
		background: url('/images/tick.svg') var(--color-red) !important

.custom-radio
	appearance: none
	width: 1rem
	height: 1rem
	border-radius: 50%

	&:checked
		border: none !important
		background: var(--color-red) !important

.specialities-list
	display: grid
	grid-gap: 1.5rem

	@media screen and (min-width: $desktop-min)
		grid-template-columns: repeat(auto-fit, minmax(18rem, 0.5fr))

.edit-button
	width: 2rem
	height: 2rem
	border-radius: 50%
	background: var(--color-red)
	padding: 0.5rem
	transition: background 0.1s ease

	&:hover
		background: var(--color-dark-red)

	img, svg
		width: 100%
		height: 100%
		object-fit: contain

//
// Multiselect styles
//
//.multiselect.dropdown, .multiselect.autocomplete
	&.tags
		.multiselect__placeholder
			margin-bottom: 0.3rem

		.multiselect__input
			margin-bottom: 0.3rem
			height: auto



.multiselect
	width: auto
	min-height: auto
	box-sizing: border-box

	*
		min-height: auto
		font-size: 0.8rem
		font-weight: 400
		letter-spacing: inherit
		line-height: inherit

	&.dropdown, &.autocomplete
		box-sizing: border-box
		background: #e0e0e0
		border-radius: var(--radius-small)
		cursor: pointer

		@media screen and (max-width: $tablet-max)
			padding: 0.8rem 2rem 0.8rem 0.8rem

		@media screen and (min-width: $desktop-min)
			padding: 0.8rem

		&.dropdown
			@media screen and (min-width: $desktop-min)
				text-align: center

		&.autocomplete
			cursor: text

	&.tags
		padding: 0

		.multiselect__placeholder, .multiselect__input
			padding: 0.8rem !important

		.multiselect__tags-wrap
			display: block
			padding: 0 0.4rem
			font-size: 0

		.multiselect__tag
			margin: 0.58rem 0.2rem
			background: var(--color-red)

			.multiselect__tag-icon::after
				color: white

			.multiselect__tag-icon:hover
				background: var(--color-dark-red)

	&.multiselect--active
		border-radius: var(--radius-small) var(--radius-small) 0 0
		box-shadow: 0 0 0 1px var(--color-red)
		z-index: auto

	.multiselect__content-wrapper
		width: 100%
		bottom: 0
		left: 0
		transform: translateY(100%)
		border: none
		border-radius: 0 0 var(--radius-small) var(--radius-small)
		box-shadow: 0 0.1rem 0.2rem #9191917d
		z-index: 100

		.multiselect__element:not(:first-child)
			.multiselect__option
					border-top: 1px solid var(--color-lighter-grey)

		.multiselect__option--highlight
			background: var(--color-red)

	.multiselect__tags
		padding: 0
		background: none
		border: none
		font-size: inherit

		.multiselect__input, .multiselect__placeholder, .multiselect__single
			min-height: 1rem
			display: block
			margin: 0
			padding: 0
			background: none
			white-space: initial

		.multiselect__input:focus
			box-shadow: none
			border-width: 0

		.multiselect__placeholder
			color: var(--color-light-grey)

		.multiselect__single
			color: black
			letter-spacing: inherit
			white-space: nowrap
			text-overflow: ellipsis
			overflow: hidden

	.multiselect__select
		width: 14px
		height: 14px
		top: calc(50% - 7px)
		right: 1rem
		padding: 0

		&::before
			top: calc(50% - 9px)
			margin: 0
			content: url('/images/arrow-down.svg')
			border: none

	&.no-arrow, &.multiselect--disabled
		.multiselect__select
			display: none

	&.italic
		*
			font-style: italic

.profile-form
	.multiselect.dropdown, .multiselect.autocomplete
		background: white !important
		border: 1px solid var(--color-lighter-grey) !important
